import Vue from 'vue'
import axios from 'axios'

const initState = {
  users: null,
  queries: null,
  totalUsers: 0,
  notifications: [],
  allNotificaiton: [],
}

export default {
  namespaced: true,
  state: Vue.util.extend({}, initState),

  mutations: {
    getAllSuccess(state, { users, queries, totalUsers }) {
      state.users = users
      state.queries = queries
      state.totalUsers = totalUsers
    },

    resetAllSuccess(state) {
      for (let f in state) {
        Vue.set(state, f, initState[f])
      }
    },
  },
  actions: {
    getAll({ commit }, queries) {
      return new Promise((resolve, reject) => {
        axios
          .get('/users', { params: queries })
          .then((res) => {
            commit('getAllSuccess', {
              users: res.data.data,
              queries,
              totalUsers: res.data.meta.total,
            })
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    resetAll({ commit }) {
      commit('resetAllSuccess')
    },

    changePassword({ commit }, params) {
      return new Promise((resolve, reject) => {
        let apiPath = params.user_id
          ? `/users/${params.user_id}/change-password`
          : '/change-password'

        axios
          .post(apiPath, params)
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    addTeamUser({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('add-team-user', params)
            .then((res) => {
              resolve(res)
            })
            .catch((error) => {
              reject(error)
            })
      })
    },
    removeTeamUser({ commit }, { userId, teamId }) {
      return new Promise((resolve, reject) => {
        axios
            .delete(`delete-team-user/${teamId}/${userId}`)
            .then((res) => {
              resolve(res)
            })
            .catch((error) => {
              reject(error)
            })
      })
    }
  },

  getters: {
    indexQueries(state) {
      return state.queries
    },

    all(state) {
      return state.users
    },

    totalUsers(state) {
      return state.totalUsers
    },
  },
}
