<template>
  <div class="left-side-menu" :class="{ 'light-variation': isLeadMachine }">
    <div class="sidebar-content">
      <div id="sidebar-menu">
        <ul id="side-menu" class="metismenu">
          <li
              v-for="(menuItem, index) in renderMenuItems"
              :key="index"
              class="side-nav-title side-nav-item"
              :class="{ 'mm-active': currentRoute.match(menuItem.pathActive), 'mm-show': menuItem.isExpanded }"
          >
            <router-link
                v-if="!menuItem.items && menuItem.route"
                :to="{ name: menuItem.route }"
                class="side-nav-link side-nav-link-ref"
                exact-active-class=".side-nav-item"
            >
              <i :class="menuItem.icon" class="uil"></i>
              <span>{{ menuItem.label }}</span>
            </router-link>

            <a
                v-if="!menuItem.items && menuItem.routeOut"
                :href="menuItem.routeOut"
                class="side-nav-link"
                target="_blank"
            >
              <i :class="menuItem.icon" class="uil"></i>
              <span>{{ menuItem.label }}</span>
            </a>

            <a
                v-if="menuItem.items"
                href="#"
                class="side-nav-link"
                :aria-expanded="menuItem.isExpanded"
                @click.prevent="clickParentItem(menuItem)"
            >
              <i :class="menuItem.icon" class="uil"></i>
              <span>{{ menuItem.label }}</span>
              <span v-if="!currentRoute.match(menuItem.pathActive)" class="menu-arrow"></span>
            </a>
            <ul
                v-if="menuItem.items"
                class="nav-second-level mm-collapse"
                :class="{ 'mm-show': currentRoute.match(menuItem.pathActive) || menuItem.isExpanded }"
            >
              <li v-for="(item, index1) in menuItem.items" :key="index1" :class="{ 'active-child': currentRoute.match(item.pathActive) }">
                <router-link :to="{ name: item.route }">
                  <span>{{ item.label }}</span>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<!--https://iconscout.com/unicons/explore/line-->
<script>
export default {
  data() {
    return {
      sideBarBackground: window.location.origin.includes('leadmachine') ? '#ffffff' : '#1e3260',
      sideBarLinkColor: window.location.origin.includes('leadmachine') ? '#818191' : '#cfd0d0',
      menuRef: null,
      menuItems: {
        Admin: [
          {
            label: 'Agencies',
            icon: 'uil-briefcase',
            pathActive: '/admin/agencies',
            route: 'admin.agencies.index',
            isExpanded:true,
            items: [
              { label: 'List', route: 'admin.agencies.index' },
              { label: 'Archived', route: 'admin.agencies.archived' },
            ],
          },
          {
            label: 'Plans',
            icon: 'uil-atm-card',
            route: 'admin.plans.index',
            pathActive: '/admin/plans',
          },
          {
            label: 'Training',
            icon: 'uil-life-ring',
            route: 'admin.settings.training',
            pathActive: '/admin/training',
          },
          {
            label: 'Emails',
            icon: 'uil-envelope-edit',
            route: 'admin.settings.emails',
            pathActive: '/admin/emails',
          },
          {
            label: 'Settings',
            icon: 'uil-cog',
            route: 'admin.settings.index',
            pathActive: '/admin/settings',
          },
          {
            label: 'Tracking',
            icon: 'uil-truck',
            route: 'admin.tracking.index',
            pathActive: '/admin/tracking',
          },
        ],
        Agency: [
          {
            label: 'Dashboard',
            icon: 'uil-home',
            pathActive: '/agency/dashboard',
            route: 'agency.dashboard',
          },
          {
            label: 'Customers',
            route: 'agency.customers.index',
            icon: 'uil-user-square',
            pathActive: '/agency/customers',
          },
          {
            label: 'Leads',
            route: 'agency.businesses.index',
            icon: 'uil-users-alt',
            pathActive: '/agency/businesses',
          },
          {
            label: 'InstaStream',
            icon: 'uil-instagram',
            pathActive: '/agency/instagram-widget',
            route: 'agency.instagram-widget.index',
          },
          {
            label: 'Landing Page',
            icon: 'uil-web-grid-alt',
            pathActive: '/agency/landing',
            route: 'agency.landing',
          },
          {
            label: 'RE Widget',
            icon: 'uil-code',
            pathActive: '/agency/embed',
            route: 'agency.embed',
          },
          {
            label: 'SR Widget',
            icon: 'uil-code',
            pathActive: '/agency/sr-widget',
            route: 'agency.sr-widget',
          },
          {
            label: 'SF Widget',
            icon: 'uil-code',
            pathActive: '/agency/sf-widget',
            route: 'agency.sf-widget',
          },
          {
            label: 'IS Widget',
            icon: 'uil-code',
            pathActive: '/agency/is-widget',
            route: 'agency.insta-stream-widget',
          },
          {
            label: 'Lead Machine',
            route:'agency.lead-machine-dashboard',
            isExpanded:false,
            icon: 'uil-apps',
            pathActive: 'lead-machine',
            type:'lead_machine',
            items:[
              {
                label: 'Dashboard',
                route: 'agency.lead-machine-dashboard',
                icon: 'uil-home',
                pathActive: '/agency/lead-machine/dashboard',
              },
              {
                label: 'Mailed Leads',
                route: 'agency.local-searches-mailed',
                icon: 'uil-envelope-open',
                pathActive: '/agency/lead-machine/local-search-mailed',
              },
              {
                label: 'Local Searches',
                route: 'agency.local-searches',
                icon: 'uil-book',
                pathActive: '/agency/lead-machine/local-searches',
              },
              {
                label: 'Letter Templates',
                route: 'agency.letter-templates',
                icon: 'uil-file-alt',
                pathActive: '/agency/lead-machine/letter-templates',
              },
              {
                label: 'Landing Pages',
                route: 'agency.le-landing-pages',
                icon: 'uil-window',
                pathActive: '/agency/lead-machine/landing-pages',
              },
              {
                label: 'Import',
                route: 'agency.lead-machine-upload',
                pathActive: '/agency/lead-machine/upload',
              }
            ]
          },
          {
            label: 'Training',
            icon: 'uil-life-ring',
            route: 'agency.training',
            pathActive: '/agency/training',
          },
          {
            label: 'Settings',
            route: 'agency.settings',
            icon: 'uil-cog',
            pathActive: '/agency/settings',
          },
          {
            label: 'Subscription',
            route: 'agency.subscription',
            icon: 'uil-invoice',
            pathActive: '/agency/subscription',
          },
        ],
        Business: [
          {
            label: 'Dashboard',
            route: 'business.dashboard',
            icon: 'uil-home',
            pathActive: '/business/dashboard',
          },
          {
            label: 'Reviews',
            route: 'business.reviews',
            icon: 'uil-chat',
            pathActive: '/business/reviews',
          },
          {
            label: 'Employees',
            route: 'business.employees',
            icon: 'uil-users-alt',
            pathActive: '/business/employees',
          },
          {
            label: 'Review Gate',
            route: 'business.review-gate',
            icon: 'uil-web-grid-alt',
            pathActive: '/business/review-gate',
          },
          {
            label: 'Review Images',
            route: 'business.review-images',
            icon: 'uil-image',
            pathActive: '/business/review-images',
          },
          {
            label: 'Review Sites',
            route: 'business.review-sites',
            icon: 'uil-link-alt',
            pathActive: '/business/review-sites',
          },
          {
            label: 'Bulk Request',
            route: 'business.review-sites-upload',
            icon: 'uil-file-upload',
            pathActive: '/business/review-bulk-upload',
          },
          {
            label: 'Contact Series',
            route: 'business.review-contact-series',
            icon: 'uil-list-ul',
            pathActive: '/business/review-contact-series',
          },
          {
            label: 'Contacts',
            route: 'business.request-contacts',
            icon: 'uil-user-check',
            pathActive: '/business/request-contacts',
          },
          {
            label: 'Social Posts',
            route: 'business.social-posts',
            icon: 'uil-image',
            pathActive: '/business/social-posts',
          },
          {
            label: 'Instagram Widget',
            route: 'business.instagram-widget',
            icon: 'uil-instagram',
            pathActive: '/business/instagram-widget',
          },
          {
            label: 'Settings',
            route: 'business.settings',
            icon: 'uil-cog',
            pathActive: '/business/settings',
          }
        ],
      },
      currentRoute: '',
    }
  },

  computed: {
    renderMenuItems() {
      if (this.user) {
        let items = this.menuItems[this.user.role];
        if (this.user.role === 'Agency') {
          const replyEngineRoutes = ['agency.landing', 'agency.embed'];
          items = items.filter(
              (i) => !this.user.agency.free_fee ||
                  (this.user.agency.free_fee &&
                      i.label !== 'Subscription' &&
                      i.label !== 'Payment methods')
          ).filter(item => {

            if (!this.user.agency.free_fee && !this.user.user_plans.filter(plan => {
              return plan.plan.product_type === 'ReplyEngine' && !plan.past_due
            }).length && replyEngineRoutes.includes(item.route)) {
              return false;
            } else {
              return true;
            }

          });
          // Remove Customers Link
          items = items.filter(
              (item) => {
                if (item.route === 'agency.customers.index' && !this.user.agency.free_fee && !this.user.has_review_requester && !this.user.has_re && !this.user.has_social_review && !this.user.has_te && !this.user.has_social_fusion && !this.user.has_insta_stream && !this.user.has_repu_agency) {
                  return false;
                } else {
                  return true;
                }
              });
          const socialReviewRoutes = ['agency.sr-widget'];
          items = items.filter(
              (item) => {
                if (!this.user.agency.free_fee && !this.user.user_plans.filter(plan => {
                  return plan.plan.product_type === 'SocialReview' && !plan.past_due
                }).length && socialReviewRoutes.includes(item.route)) {
                  return false;
                } else {
                  return true;
                }

              });

          const socialFusionRoutes = ['agency.sf-widget'];
          items = items.filter(
              (item) => {
                if (!this.user.agency.free_fee && !this.user.user_plans.filter(plan => {
                  return plan.plan.product_type === 'SocialFusion' && !plan.past_due
                }).length && socialFusionRoutes.includes(item.route)) {
                  return false;
                } else {
                  return true;
                }

              });
          const instaStreamRoutes = ['agency.instagram-widget.index', 'agency.insta-stream-widget'];
          items = items.filter(
              (item) => {
                if (!this.user.agency.free_fee && !this.user.user_plans.filter(plan => {
                  return plan.plan.product_type === 'InstaStream' && !plan.past_due
                }).length && instaStreamRoutes.includes(item.route)) {
                  return false;
                } else {
                  return true;
                }

              });


          const ReSrRoutes = ['agency.businesses.index'];
          items = items.filter(
              (item) => {
                if (!this.user.agency.free_fee && !this.user.user_plans.filter(plan => {
                  return (plan.plan.product_type === 'SocialReview' || plan.plan.product_type === 'ReplyEngine' || plan.plan.product_type === 'ReviewSurge' || plan.plan.product_type === 'SocialFusion' || plan.plan.product_type === 'InstaStream') && !plan.past_due
                }).length && ReSrRoutes.includes(item.route)) {
                  return false;
                } else {
                  return true;
                }

              });

          // const leadMachineRoutes = ['agency.local-searches', 'agency.letter-templates','agency.lead-machine-dashboard','agency.local-searches-mailed','agency.le-landing-pages'];
          items = items.filter(
              (item) => {
                if (!this.user.agency.free_fee && !this.user.user_plans.filter(plan => {
                  return plan.plan.product_type === 'LeadMachine' && !plan.past_due
                }).length && item.type === 'lead_machine') {
                  return false;
                } else {
                  return true;
                }
              });

        }
        if (this.user.role === 'Business') {
          const tapEngineRoutes = ['business.dashboard', 'business.review-gate', 'business.employees', 'business.reviews', 'business.settings'];
          const socialReviewRoutes = ['business.dashboard', 'business.review-images', 'business.reviews', 'business.settings'];
          const socialFusionRoutes = ['business.social-posts', 'business.settings'];
          const textRequesterRoutes = ['business.dashboard', 'business.review-sites', 'business.review-sites-upload', 'business.review-contact-series', 'business.review-gate', 'business.settings', 'business.reviews', 'business.request-contacts', 'business.employees'];
          const replyEngineBusinessRoutes = ['business.dashboard', 'business.reviews', 'business.review-gate', 'business.settings'];
          const instagramWidgetRoutes = ['business.instagram-widget', 'business.settings'];
          const settingsRoute = ['business.settings'];
          items = items.filter((item) => {
            if (this.user.business) {
              if (this.user.business.is_tap_engine && tapEngineRoutes.includes(item.route)) {
                return true;
              }
              if (this.user.business.is_social_fusion && socialFusionRoutes.includes(item.route)) {
                return true;
              }
              if (this.user.business.is_social_review && socialReviewRoutes.includes(item.route)) {
                return true;
              }
              if (this.user.business.is_text_requester && textRequesterRoutes.includes(item.route)) {
                return true;
              }
              if (this.user.business.is_reply_engine && replyEngineBusinessRoutes.includes(item.route)) {
                return true;
              }
              if (this.user.business.is_insta_stream && instagramWidgetRoutes.includes(item.route)) {
                return true;
              }
              if(!(this.user.business.is_insta_stream && this.user.business.is_reply_engine && this.user.business.is_tap_engine && this.user.business.is_social_fusion && this.user.business.is_social_review && this.user.business.is_text_requester) && settingsRoute.includes(item.route)){
                return true;
              }
              return false;
            } else if ([...tapEngineRoutes, ...socialReviewRoutes, ...textRequesterRoutes, ...replyEngineBusinessRoutes, ...socialFusionRoutes, ...instagramWidgetRoutes, ...settingsRoute].includes(item.route)) {
              return false;
            }
          });
        }

        return items
      }
      return []
    },

    isImpersonating() {
      return this.$store.getters['auth/isImpersonating']
    },

    showNotification() {
      return this.userRole === 'Agency'
    },

    userRole() {
      return this.user ? this.user.role : ''
    },

    user() {
      return this.$store.getters['auth/user']
    },
  },

  mounted() {
    if (this.isLeadMachine) {
      document.body.classList.add('lm-variation')
    }
    this.currentRoute = this.$router.currentRoute.path

    this.$router.afterEach((to, from) => {
      this.currentRoute = to.path
    })
  },

  methods: {
    clickParentItem(menuItem) {
      menuItem.isExpanded = !menuItem.isExpanded;
    },
    stopImpersonate() {
      this.loadingStopImpersonate = true

      this.$store
          .dispatch('auth/stopImpersonate')
          .then(() => {
            this.$store
                .dispatch('auth/getProfile')
                .then((user) => {
                  this.$router.push('/' + this.userRole.toLocaleLowerCase())
                  this.loadingStopImpersonate = false
                })
                .catch(() => {
                  this.loadingStopImpersonate = false
                })
          })
          .catch(() => {
            this.loadingStopImpersonate = false
          })
    },

    logout() {
      this.$store
          .dispatch('auth/logout')
          .then(() => {
            this.$router.push({name: 'login'})
          })
          .catch(() => {
          })
    },
  },
}
</script>

<style lang="scss">
.slimscroll-menu {
  height: 100%;
}
.ps > .ps__scrollbar-y-rail {
  width: 8px !important;
  background-color: transparent !important;
}
.ps > .ps__scrollbar-y-rail > .ps__scrollbar-y,
.ps.ps--in-scrolling.ps--y > .ps__scrollbar-y-rail > .ps__scrollbar-y,
.ps > .ps__scrollbar-y-rail:active > .ps__scrollbar-y,
.ps > .ps__scrollbar-y-rail:hover > .ps__scrollbar-y {
  width: 6px !important;
}
.left-side-menu {
  padding-bottom: 10px;
  a.side-nav-link {
    i {
      margin-right: 15px !important;
    }
  }
}
.left-side-menu .sidebar-content {
  height: 100% !important;
  display: flex;
  flex-direction: column;
  padding-bottom: 0px;
}
#sidebar-menu {
  flex: 1 1 auto;
}
.mm-active, .nav-second, .nav-second-level{
  background: #112144;
}
.nav-second-level{
  border-bottom: 2px solid #112144;
}
.light-variation{
  .mm-active, .nav-second, .nav-second-level{
    background: #c2bfbf;
    a{
      color: #2c2e2f;
    }
    a.router-link-active{
      color: #c2bfbf;
    }
  }
  .nav-second-level{
    border-bottom: 2px solid #c2bfbf;
  }
}
</style>
