import lazyLoading from './lazyLoading'
import DashboardLayout from '@layouts/Dashboard'
import BusinessGuard from './guards/business-guard'

export default [
  {
    path: '/business/',
    component: DashboardLayout,
    beforeEnter: BusinessGuard,
    redirect: { name: 'business.dashboard' },
    children: [
      {
        name: 'business.dashboard',
        path: 'dashboard',
        component: lazyLoading('Business/Dashboard'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.reviews',
        path: 'reviews',
        component: lazyLoading('Business/Reviews'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.employees',
        path: 'employees',
        component: lazyLoading('Business/Employees'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.review-gate',
        path: 'review-gate',
        component: lazyLoading('Business/ReviewGateAdmin'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.review-sites',
        path: 'review-sites',
        component: lazyLoading('Business/ReviewSitesAdmin'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.review-sites-upload',
        path: 'review-bulk-upload',
        component: lazyLoading('Business/ReviewSitesUpload'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.review-contact-series',
        path: 'review-contact-series',
        component: lazyLoading('Business/Automations/Index'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.review-images',
        path: 'review-images',
        component: lazyLoading('Business/ReviewImages'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.social-posts',
        path: 'social-posts',
        component: lazyLoading('Business/SocialPosts'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.review-image-editor',
        path: 'review-image-editor/:platform',
        component: lazyLoading('Business/ReviewImageEditor'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.profile',
        path: 'profile',
        component: lazyLoading('Common/Profile'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.settings',
        path: 'settings',
        component: lazyLoading('Business/Settings'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.setup-phonenumber',
        path: 'setups/phone-number',
        component: lazyLoading('Business/Setups/PhoneNumber'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.process-review',
        path: ':id/review',
        component: lazyLoading('Business/ProcessReview'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.request-contacts',
        path: 'request-contacts',
        component: lazyLoading('Business/RequestContacts'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.instagram-widget',
        path: 'instagram-widget',
        component: lazyLoading('Business/InstaWidget'),
        beforeEnter: BusinessGuard,
      },
    ],
  },
]
