import Vue from 'vue'
import axios from 'axios'

const initState = {
  currentPage: 'index',
  index: {
    employees: null,
    total: 0,
    queries: {
      per_page: 10,
      page: 1,
      status: 'active',
      search: null,
      sort_by: 'id',
      sort_desc: false,
    },
  },
  archived: {
    businesses: null,
    total: 0,
    queries: {
      per_page: 10,
      page: 1,
      status: 'archived',
      search: null,
      sort_by: 'id',
      sort_desc: false,
    },
  },
}

export default {
  namespaced: true,

  state: Vue.util.extend({}, initState),

  mutations: {
    getAllSuccess(state, { page, total, queries, employees }) {
      state[page] = {
        total: total,
        queries: queries,
        employees:employees
      }
    },

    updateBroadcastersSuccess(state, user) {
      this.state.auth.user = user
    },

    resetAllSuccess(state) {
      for (let f in state) {
        Vue.set(state, f, initState[f])
      }
    },

    currentPage(state, page) {
      state.currentPage = page
    },
  },

  actions: {
    getAll({ commit }, { queries, page }) {
      return new Promise((resolve, reject) => {
        axios
            .get('/employees', { params: queries })
            .then((res) => {
              commit('getAllSuccess', {
                page: page,
                total: res.data.meta.total,
                queries: queries,
                employees: res.data.data,
              })
              resolve(true)
            })
            .catch((error) => {
              reject(error)
            })
      })
    },

    create({ commit }, employee) {
      return new Promise((resolve, reject) => {
        axios
            .post(`/employees/create`, employee)
            .then((res) => {
              resolve(res.data.data)
            })
            .catch((error) => {
              reject(error)
            })
      })
    },

    update({ commit }, employee) {
      return new Promise((resolve, reject) => {
        axios
            .post(`/employees/update/${employee.id}`, employee)
            .then((res) => {
              resolve(res.data.data)
            })
            .catch((error) => {
              reject(error)
            })
      })
    },

    find({ commit }, employeeId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/employees/${employeeId}`)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    delete({ commit }, employeeId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/employees/${employeeId}`)
          .then(() => {
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    resetAll({ commit }) {
      commit('resetAllSuccess')
    },

    setCurrentPage({ commit }, page) {
      commit('currentPage', page)
    },

  },

  getters: {
    all(state) {
      return state[state.currentPage].employees
    },

    queries(state) {
      return state[state.currentPage].queries
    },

    total(state) {
      return state[state.currentPage].total
    },
  },
}
