import lazyLoading from './lazyLoading'
import DashboardLayout from '@layouts/Dashboard'
import TeamGuard from "@router/guards/team-guard";

export default [
  {
    path: '/team/',
    component: DashboardLayout,
    beforeEnter: TeamGuard,
    redirect: { name: 'team.dashboard' },
    children: [
      {
        name: 'team.dashboard',
        path: 'dashboard',
        component: lazyLoading('Team/CustomersIndex'),
        beforeEnter: TeamGuard,
      }
    ],
  },
]
