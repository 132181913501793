<template>
  <div class="left-side-menu">
    <div class="sidebar-content">
      <div id="sidebar-menu">
        <ul id="side-menu" class="metismenu">
          <li
              v-for="(menuItem, index) in renderMenuItems"
              :key="index"
              class="side-nav-title side-nav-item"
              :class="{ 'mm-active': currentRoute.match(menuItem.pathActive) }"
          >
            <router-link
                v-if="!menuItem.items && menuItem.route"
                :to="{ name: menuItem.route }"
                class="side-nav-link side-nav-link-ref"
                exact-active-class=".side-nav-item"
            >
              <i :class="menuItem.icon" class="uil"></i>
              <span>{{ menuItem.label }}</span>
            </router-link>

            <a
                v-if="!menuItem.items && menuItem.routeOut"
                :href="menuItem.routeOut"
                class="side-nav-link"
                target="_blank"
            >
              <i :class="menuItem.icon" class="uil"></i>
              <span>{{ menuItem.label }}</span>
            </a>

            <router-link
                v-if="menuItem.items"
                :to="{ name: menuItem.route }"
                class="side-nav-link"
                :aria-expanded="currentRoute.match(menuItem.pathActive) !== null"
                @click="clickParentItem(menuItem)"
            >
              <i :class="menuItem.icon" class="uil"></i>
              <span>{{ menuItem.label }}</span>
              <span class="menu-arrow"></span>
            </router-link>

            <ul
                v-if="menuItem.items"
                class="nav-second-level mm-collapse"
                :class="{ 'mm-show': currentRoute.match(menuItem.pathActive) }"
            >
              <li v-for="(item, index1) in menuItem.items" :key="index1">
                <router-link :to="{ name: item.route }">{{
                    item.label
                  }}</router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuRef: null,
      menuItems: {
        Admin: [
          {
            label: 'Agencies',
            icon: 'uil-briefcase',
            pathActive: '/admin/agencies',
            route: 'admin.agencies.index',
            items: [
              { label: 'List', route: 'admin.agencies.index' },
              { label: 'Archived', route: 'admin.agencies.archived' },
            ],
          },
          {
            label: 'Plans',
            icon: 'uil-atm-card',
            route: 'admin.plans.index',
            pathActive: '/admin/plans',
          },
          {
            label: 'Training',
            icon: 'uil-life-ring',
            route: 'admin.settings.training',
            pathActive: '/admin/training',
          },
          {
            label: 'Emails',
            icon: 'uil-envelope-edit',
            route: 'admin.settings.emails',
            pathActive: '/admin/emails',
          },
          {
            label: 'Settings',
            icon: 'uil-cog',
            route: 'admin.settings.index',
            pathActive: '/admin/settings',
          },
        ],
        Agency: [
          {
            label: 'Widgets',
            icon: 'uil-user-square',
            pathActive: '/agency/google-stream-widgets',
            route: 'agency.widgets.index',
          },
          {
            label: 'Leads',
            route: 'agency.dashboard',
            icon: 'uil-users-alt',
            pathActive: '/agency/dashboard',
          },
          {
            label: 'Landing Page',
            icon: 'uil-web-grid-alt',
            pathActive: '/agency/landing',
            route: 'agency.landing',
          },
          {
            label: 'Embed Form',
            icon: 'uil-code',
            pathActive: '/agency/widgets/zensocial-widget',
            route: 'agency.zensocial-widget',
          },
          {
            label: 'Training',
            icon: 'uil-life-ring',
            route: 'agency.training',
            pathActive: '/agency/training',
          },
          {
            label: 'Settings',
            route: 'agency.settings',
            icon: 'uil-cog',
            pathActive: '/agency/settings',
          },
          {
            label: 'Subscription',
            route: 'agency.subscription',
            icon: 'uil-invoice',
            pathActive: '/agency/subscription',
          },
        ],
        Business: [
          {
            label: 'Dashboard',
            route: 'business.dashboard',
            icon: 'uil-home',
            pathActive: '/business/dashboard',
          },
          {
            label: 'Settings',
            route: 'business.settings',
            icon: 'uil-cog',
            pathActive: '/business/settings',
          },
        ],
      },
      currentRoute: '',
    }
  },

  computed: {
    renderMenuItems() {
      if (this.user)
      {
        let items = this.menuItems[this.user.role]

        if (this.user.role === 'Agency') {
          items = items.filter(
              (i) =>
                  !this.user.agency.free_fee ||
                  (this.user.agency.free_fee &&
                      i.label !== 'Subscription' &&
                      i.label !== 'Payment methods')
          )
        }

        return items
      }
      return []
    },

    isImpersonating() {
      return this.$store.getters['auth/isImpersonating']
    },

    showNotification() {
      return this.userRole === 'Agency'
    },

    userRole() {
      return this.user ? this.user.role : ''
    },

    user() {
      return this.$store.getters['auth/user']
    },
  },

  mounted() {
    this.currentRoute = this.$router.currentRoute.path

    this.$router.afterEach((to, from) => {
      this.currentRoute = to.path
    })
  },

  methods: {
    stopImpersonate() {
      this.loadingStopImpersonate = true

      this.$store
          .dispatch('auth/stopImpersonate')
          .then(() => {
            this.$store
                .dispatch('auth/getProfile')
                .then((user) => {
                  this.$router.push('/' + this.userRole.toLocaleLowerCase())
                  this.loadingStopImpersonate = false
                })
                .catch(() => {
                  this.loadingStopImpersonate = false
                })
          })
          .catch(() => {
            this.loadingStopImpersonate = false
          })
    },

    logout() {
      this.$store
          .dispatch('auth/logout')
          .then(() => {
            this.$router.push({ name: 'login' })
          })
          .catch(() => {})
    },
  },
}
</script>

<style lang="scss">
.slimscroll-menu {
  height: 100%;
}
.ps > .ps__scrollbar-y-rail {
  width: 8px !important;
  background-color: transparent !important;
}
.ps > .ps__scrollbar-y-rail > .ps__scrollbar-y,
.ps.ps--in-scrolling.ps--y > .ps__scrollbar-y-rail > .ps__scrollbar-y,
.ps > .ps__scrollbar-y-rail:active > .ps__scrollbar-y,
.ps > .ps__scrollbar-y-rail:hover > .ps__scrollbar-y {
  width: 6px !important;
}
.left-side-menu {
  padding-bottom: 10px;
  a.side-nav-link{
    i{
      margin-right: 15px !important;
    }
  }
}
.left-side-menu .sidebar-content {
  height: 100% !important;
  display: flex;
  flex-direction: column;
  padding-bottom: 0px;
}
#sidebar-menu {
  flex: 1 1 auto;
}
</style>
