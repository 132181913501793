import Vue from 'vue'
import axios from 'axios'

const initState = {
  currentPage: 'index',
  index: {
    widgets: null,
    total: 0,
    queries: {
      per_page: 10,
      page: 1,
      status: 'active',
      prospect_status:'all',
      search: null,
      sort_by: 'id',
      sort_desc: false,
    },
  },
  archived: {
    widgets: null,
    total: 0,
    queries: {
      per_page: 10,
      page: 1,
      status: 'archived',
      search: null,
      sort_by: 'id',
      sort_desc: false,
    },
  },
}

export default {
  namespaced: true,

  state: Vue.util.extend({}, initState),

  mutations: {
    getAllSuccess(state, { widgets, page, total, queries }) {
      state[page] = {
        widgets: widgets,
        total: total,
        queries: queries,
      }
    },

    createSuccess(state, { widgets }) {
      state.index.widgets = null
    },

    updateSuccess(state, { id, widgets }) {
    },

    archiveSuccess(state) {
      state.archived.widgets = null
    },

    restoreSuccess(state) {
      state.index.widgets = null
    },

    editSettingsSuccess(state, user) {
      this.state.auth.user = user
    },

    updateBroadcastersSuccess(state, user) {
      this.state.auth.user = user
    },

    resetAllSuccess(state) {
      for (let f in state) {
        Vue.set(state, f, initState[f])
      }
    },

    currentPage(state, page) {
      state.currentPage = page
    },
  },

  actions: {
    getAll({ commit }, { queries, page }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/widgets', { params: queries })
          .then((res) => {
            commit('getAllSuccess', {
              widgets: res.data.data,
              page: page,
              total: res.data.meta.total,
              queries: queries,
            })
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    find({ commit }, widgetId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/widgets/${widgetId}`)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    create({ commit }, widget) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/widgets`, widget)
          .then((res) => {
            commit('createSuccess', { widget: res.data.data })
            resolve(true)
          })
          .catch((error) => {
            console.log(error);
            reject(error)
          })
      })
    },
    create_location_widget({ commit }, widget) {
      return new Promise((resolve, reject) => {
        axios
            .post(`/location-widget`, widget)
            .then((res) => {
              commit('createSuccess', { widget: res.data.data })
              resolve(true)
            })
            .catch((error) => {
              console.log(error);
              reject(error)
            })
      })
    },
    createSab({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
            .post(`/createsabwidget`, payload)
            .then((res) => {
              if(res.data.success) {
                commit('createSuccess', {widget: res.data.data})
              }
              resolve(res.data);
            })
            .catch((error) => {
              reject(error)
            })
      })
    },

    canCreate({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
            .post(`/widgets/cancreate`, payload)
            .then((res) => {
              resolve(res.data);
            })
            .catch((error) => {
              reject(error)
            })
      })
    },

    reScrape({ commit }, businessId) {
      return new Promise((resolve, reject) => {
        axios
            .post(`/widgets/rescrape/${businessId}`,{ 'is_admin': true})
            .then((res) => {
              resolve(res.data);
            })
            .catch((error) => {
              reject(error)
            })
      })
    },

    update({ commit }, widget) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/widgets/${widget.id}`, widget)
          .then((res) => {
            commit('updateSuccess', {
              id: widget.id,
              widget: res.data.data,
            })
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    archive({ commit }, widgetId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/widgets/${widgetId}`)
          .then(() => {
            commit('archiveSuccess')
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    delete({ commit }, widgetId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/widgets/${widgetId}`)
          .then(() => {
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    restore({ commit }, widgetId) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/widgets/${widgetId}/restore`)
          .then((res) => {
            commit('restoreSuccess')
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    resetAll({ commit }) {
      commit('resetAllSuccess')
    },

    setCurrentPage({ commit }, page) {
      commit('currentPage', page)
    },

  },

  getters: {
    all(state) {
      return state[state.currentPage].widgets
    },

    queries(state) {
      return state[state.currentPage].queries
    },

    total(state) {
      return state[state.currentPage].total
    },
  },
}
