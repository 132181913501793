import store from '@store/index'

function AgencyGuard(to, from, next) {
  let user = store.getters['auth/user'];
  let config = store.getters['app/config'];
  const isRepuAgency = origin.includes('localhost') ||
      origin.includes('repuagency.com') ||
      (config?.custom_domain && origin.includes(config.custom_domain));
  const isZenSocial = origin.includes('zensocial.io');
  if (!user) {
    next({ name: 'login' })
  } else if (user.role !== 'Agency') {
    next('/')
  }else if(user.role === 'Agency' && user.has_te && (to.name !== 'agency.dashboard' && to.name !== 'agency.customers.index') && (user?.address?.address === '' || user?.address?.city === '' || user?.address?.state === '' || user?.address?.zip === '' || user?.address?.country === '') ){
    if(isRepuAgency || isZenSocial){
      next()
    }else{
      next({ name: 'agency.dashboard' });
    }
  } else if(user.agency.owner.on_trial){
    next()
  }else if (!isZenSocial && !user.subscribed && !user.agency.free_fee) {
    if (to.name !== 'agency.subscription' && to.name !== 'agency.profile' && !to.name.startsWith('agency.payment-methods')) {
      next({ name: 'agency.subscription'})
    } else {
      next()
    }
  } else {
    next()
  }
}

export default AgencyGuard
