import store from '@store/index'

function AuthGuard(to, from, next) {
  let user = store.getters['auth/user'];
  let config = store.getters['app/config'];
  const isRepuAgency = origin.includes('localhost') ||
      origin.includes('repuagency.com') ||
      (config?.custom_domain && origin.includes(config.custom_domain));
  if (user) {
    if (user.role === 'Admin') {
      next({ name: 'admin.agencies.index' })
    } else if (user.role === 'Team') {
      next({ name: 'team.member' })
    }  else if (user.role === 'Agency') {
      if(isRepuAgency){
        next({ name: 'agency.customers.index' })
      }else{
        next({ name: 'agency.dashboard' })
      }
    }else if(isRepuAgency){
      next({ name: 'business.dashboard' });
    } else if ( user.business.is_social_fusion ) {
      next({ name: 'business.social-posts' })
    } else if ( user.business.is_insta_stream ) {
      next({ name: 'business.instagram-widget' })
    } else {
      next({ name: 'business.dashboard' })
    }
  } else {
    next()
  }
}

export default AuthGuard
