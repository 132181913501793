<!--eslint-disable vue/camelcase-->
<template>
  <div class="navbar navbar-expand flex-column flex-md-row navbar-custom">
    <div class="container-fluid">
      <router-link
        v-if="config && (routeName.startsWith('business.') ? config.logo : true)"
        class="navbar-brand mr-0 mr-md-2 logo"
        :to="'/' + userRole.toLocaleLowerCase()"
      >
        <span class="logo-lg">
          <img v-if="config.logo" :src="config.logo" style="display:block;margin:auto;max-width:210px;max-height:50px;"/>
          <img v-else-if="origin === 'https://app.showmyrep.io' || origin.includes('rvrply.com') || origin.includes('reviewour.biz')" src="@assets/images/Blank.png" alt width="210" />
          <img v-else-if="origin.includes('tapengine.io')" src="@assets/images/tapengine-logo.png" style="display:block;margin:auto;height:40px;max-width:100%;" alt="tapengine"/>
          <img v-else-if="origin.includes('getsocialreviews.com')" src="@assets/images/social-review-logo.png" style="display:block;margin:auto;height:45px;max-width:100%;"/>
          <img v-else-if="origin.includes('reviewsurge.co')" src="@assets/images/review-surge-logo.png" style="display:block;margin:auto;height:40px;max-width:100%;"/>
          <img v-else-if="origin.includes('leadmachine.io')" src="@assets/images/lead-machine-logo.png" style="display:block;margin:auto;height:25px;max-width:100%;"/>
          <img v-else-if="origin.includes('socialfusionai.com')" src="@assets/images/social-fusion-logo.png" style="display:block;margin:auto;height:25px;max-width:100%;"/>
          <img v-else-if="origin.includes('instastream.co')" src="@assets/images/insta-stream-logo.png" style="display:block;margin:auto;height:25px;max-width:100%;"/>
          <img v-else-if="origin.includes('zensocial.io') || isZenSocial" src="@assets/images/zensocial-logo.png" style="display:block;margin:auto;height:45px;max-width:100%;"/>
          <img v-else-if="isRepuAgency || origin.includes('repuagency.com')"
               src="@assets/images/repuagency-logo.png"
               style="display:block;margin:auto;max-width:200px;"
               srcset="@assets/images/repuagency-logo-dark.png 768w, @assets/images/repuagency-logo.png 769w"
          />
          <img v-else src="@assets/images/logo.png" alt height="50" />
        </span>
        <span class="logo-sm">
          <img v-if="isRepuAgency" src="@assets/images/repuagency-favicon.png" alt height="50" />
          <img v-else-if="isZenSocial" src="@assets/images/zensocial-favicon.png" alt height="50" />
          <img v-else src="@assets/images/favicon.png" alt height="50" />
        </span>
      </router-link>
      <div v-else class="navbar-brand" style="margin-right: 10px;"></div>
      <ul
        class="navbar-nav bd-navbar-nav flex-row list-unstyled menu-left mb-0 mr-auto"
      >
        <li>
          <button
            class="button-menu-mobile open-left"
            :class="{ open: isMenuOpened }"
            @click="toggleMenu"
          >
            <template v-if="isRepuAgency">
              <Feather v-if="isMenuOpened" class="icon-sm" type="chevron-left" />
              <Feather v-else class="icon-sm" type="chevron-right" />
            </template>
            <template v-else>
              <feather type="menu" class="menu-icon align-middle"></feather>
            </template>
          </button>
        </li>
        <li v-if="user && (user.has_premium_plan || isAdmin || isTeamMember || user.role === 'Team') && availableTeams.length" class="align-content-center">
         <TeamSelect />
        </li>
      </ul>
      <ul
        class="navbar-nav flex-row ml-auto d-flex list-unstyled topnav-menu float-right mb-0"
      >
        <li v-if="user && user.role === 'Admin'" class="mt-auto mb-auto mr-4">
          <router-link
              :to="{ name: 'admin.agencies.create' }"
              class="btn btn-light"
          >
            <i class="uil uil-plus"></i> New Agency
          </router-link>
        </li>
        <li v-if="user && user.business && user.business.is_text_requester" class="mt-auto mb-auto mr-4">
          <router-link
              :to="{ name: 'request-reviews', params: { unique_id: user.business.unique_id + '-' + user.business.id } }"
              class="btn btn-light"
              target="_blank"
          >Request Review
          </router-link>
        </li>
        <li v-if="user && user.role === 'Agency'" class="m-auto header-btns">
          <router-link v-if="routeName !== 'agency.widgets.index' && isZenSocial" :to="{ name: 'agency.widgets.index', query: { 'create_widget': true } }" class="btn btn-success border-dark mr-4 btn-create-widget">Create Widget</router-link>
          <router-link v-if="routeName !== 'agency.businesses.index' && (user.has_re || user.has_te || user.has_social_review || user.has_review_requester) && !isZenSocial" :to="{ name: 'agency.businesses.index', query: { 'create_lead': true } }" class="btn btn-success border-dark mr-4 btn-create-widget">Create Customer</router-link>
          <a v-if="isZenSocial" :href="'https://u.reviewour.biz/p/' + user.agency.zensocial_landing_page.zensocial_identifier" class="btn btn-primary btn-landing" target="_blank">Go To Landing Page</a>
          <a v-else-if="(user.has_re || user.agency.free_fee || isRepuAgency)" :href="landingPageDomain" class="btn btn-primary btn-landing" target="_blank">Go To Landing Page</a>
          <router-link v-if="!user.has_re && (user.has_lead_machine || user.agency.free_fee)" :to="{ name: 'agency.local-searches', query: { 'create_search': true } }" class="btn btn-success border-dark ml-md-4 btn-create-widget">Create Search</router-link>
        </li>
        <b-nav-item-dropdown
            id="user-info"
            right
            variant="white"
            class="user-list"
            menu-class="dropdown-lg"
        >
          <template v-if="user" v-slot:button-content>
            <img :src="user.avatar" class="avatar-sm rounded-circle mr-2" />
            <span v-if="!isRepuAgency" class="text-white">{{ user.full_name }}</span>
            <feather v-if="!isRepuAgency" type="chevron-down" class="align-middle ml-2"></feather>
          </template>
          <b-dropdown-item v-if="isImpersonating" @click="stopImpersonate">
            <feather type="log-in" class="icon-dual icon-xs mr-2"></feather>
            <span>Main account</span>
          </b-dropdown-item>
          <b-dropdown-item
              v-if="user"
              :to="{ name: `${userRole.toLocaleLowerCase()}.profile` }"
          >
            <feather type="user" class="icon-dual icon-xs mr-2"></feather>
            <span>My Account</span>
          </b-dropdown-item>
          <!-- <b-dropdown-item v-if="user" :to="{ name: `${userRole.toLocaleLowerCase()}.notifications.index` }">
            <feather type="headphones" class="icon-dual icon-xs mr-2"></feather>
            <span>Notification</span>
          </b-dropdown-item> -->

          <b-dropdown-divider></b-dropdown-divider>

          <b-dropdown-item class="notify-item p-0" @click.prevent="logout">
            <feather type="log-out" class="icon-dual icon-xs mr-2"></feather>
            <span>Logout</span>
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </ul>
    </div>
  </div>
</template>

<script>
import TeamSelect from "@layouts/Partials/TeamSelect.vue";
export default {
  components: {
    TeamSelect
  },

  data() {
    return {
      loadingStopImpersonate: false,
      isMenuOpened: true,
      loadingMark: false,
      origin:window.location.origin,
    }
  },

  computed: {
    landingPageDomain(){
      const landingDomain = this.config?.custom_domain || 'app.showmyrep.io';
      return `//${landingDomain}/p/${this.user.agency.subdomain}`;
    },
    isAdmin(){
      return localStorage.getItem('main_access_role') === 'Admin';
    },
    user() {
      return this.$store.getters['auth/user']
    },

    userRole() {
      return this.user ? this.user.role : ''
    },

    isImpersonating() {
      return this.$store.getters['auth/isImpersonating']
    },

    config() {
      return this.$store.getters['app/config']
    },

    notifications() {
      return this.$store.getters['user/notifications'] || []
    },

    totalUnreadNotifications() {
      return this.notifications.length
    },
    routeName(){
      return this.$route.name;
    },
    availableTeams() {
      if (this.isImpersonating) {
        // If impersonating, use the stored agency teams
        return JSON.parse(localStorage.getItem('existing_teams')) || this.user.teams;
      }
      return this.user.teams;
    }
  },

  mounted() {
    // document.body.classList.toggle('left-side-menu-condensed')
  },

  methods: {
    toggleMenu() {
      document.body.classList.toggle('left-side-menu-condensed')
      document.body.classList.toggle('sidebar-enable')
      this.$emit('toggleMenu', true)
      this.isMenuOpened = !this.isMenuOpened;
    },

    logout() {
      this.$store
        .dispatch('auth/logout')
        .then(() => {
          this.$router.push({ name: 'login' })
        })
        .catch(() => {})
    },

    stopImpersonate() {
      this.loadingStopImpersonate = true

      this.$store
        .dispatch('auth/stopImpersonate')
        .then(() => {
          this.$store
            .dispatch('auth/getProfile')
            .then((user) => {
              this.$router.push('/' + this.userRole.toLocaleLowerCase())
              this.loadingStopImpersonate = false
            })
            .catch(() => {
              this.loadingStopImpersonate = false
            })
        })
        .catch(() => {
          this.loadingStopImpersonate = false
        })
    },

    markAllAsRead() {
      this.loadingMark = true
      this.$store
        .dispatch('user/markAllNotificationAsRead')
        .then(() => {
          this.loadingMark = false
        })
        .catch(() => {
          this.loadingMark = false
        })
    },
  },
}
</script>

<style lang="scss">
.noti-scroll {
  height: 220px;
}
.ps > .ps__scrollbar-y-rail {
  width: 8px !important;
  background-color: transparent !important;
}
.ps > .ps__scrollbar-y-rail > .ps__scrollbar-y,
.ps.ps--in-scrolling.ps--y > .ps__scrollbar-y-rail > .ps__scrollbar-y,
.ps > .ps__scrollbar-y-rail:active > .ps__scrollbar-y,
.ps > .ps__scrollbar-y-rail:hover > .ps__scrollbar-y {
  width: 6px !important;
}
.button-menu-mobile {
  outline: none !important;
}
#app.repuagency{
  .navbar-custom .menu-left {
    overflow:visible;
    .button-menu-mobile {
      margin-left: -40px;
      z-index: 100000;

      i {
        background: #3b7d23;
        border-radius: 50%;
        padding: 2px;
        color: white;
        svg{
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
@media screen and (max-width: 640px){
  .header-btns{
    text-align: center;
  }
  .btn-landing,
  .btn-create-widget{
    padding: 4px 7px;
    font-size: .75em;
    margin-right: 5px !important;
    width: 120px;
  }

}
</style>
